import React, { useState, useEffect } from 'react';
import './navbar.css';
import { AiFillCloseCircle, AiFillInstagram, AiOutlineWhatsApp } from 'react-icons/ai';
import { TbGridDots } from 'react-icons/tb';
import logo from '../../Assets/img_logo.PNG';

const Navbar = ({ logoWidth = '150px', logoHeight = 'auto' }) => {
  const [active, setActive] = useState('navBar');
  const [width, setWidth] = useState(logoWidth);
  const [height, setHeight] = useState(logoHeight);
  const [onTop, setOnTop] = useState(true);

  const showNav = () => {
    setActive('navBar activeNavbar');
  };

  const removeNav = () => {
    setActive('navBar');
  };

  const addBg = () => {
    if (window.scrollY >= 10) {
      setOnTop(false);
      setWidth('80px');
      setHeight('auto');
    } else {
      setWidth('150px');
      setHeight('auto');
      setOnTop(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', addBg);
    return () => window.removeEventListener('scroll', addBg);
  }, []);

  const handleInstagramClick = () => {
    window.open('https://www.instagram.com/uiren__kz?igsh=MWd6dTN2dmJoMWl2cw==/', '_blank');
  };

  const handleWhatsAppClick = () => {
    window.open('https://wa.me/+77073478844', '_blank');
  };

  return (
    <section className="navBarSection">
      <header className={`header ${onTop ? '' : 'activeHeader'}`}>
        <div className="logoDiv">
          <img src={logo} alt="Logo" style={{ width: width, height: height }} />
          <div className="logoText flex">{onTop ? '' : 'Uiren'}
          <AiFillInstagram className="icon" onClick={handleInstagramClick} />
          <AiOutlineWhatsApp className="icon" onClick={handleWhatsAppClick} />
          </div>
        </div>

        <div className={active}>
          <ul onClick={removeNav} className="navLists">
            <li className="navItem">
              <a href="#home" className="navLink">
                Главная
              </a>
            </li>
            <li className="navItem">
              <a href="#offers" className="navLink">
                Услуги
              </a>
            </li>
            <li className="navItem">
              <a href="#coaches" className="navLink">
                Преподаватели
              </a>
            </li>
            <li className="navItem">
              <a href="#contacts" className="navLink">
                Контакты
              </a>
            </li>
          </ul>
          <div onClick={removeNav} className="closeNavbar">
            <AiFillCloseCircle className="icon" />
          </div>
        </div>

        <div onClick={showNav} className="toggleNavbar">
          <TbGridDots className="icon" />
        </div>

        {/* <div className="socialIcons">
          <AiFillInstagram className="icon" onClick={handleInstagramClick} />
          <AiOutlineWhatsApp className="icon" onClick={handleWhatsAppClick} />
        </div> */}
      </header>
    </section>
  );
};

export default Navbar;
