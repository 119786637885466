import React, { useEffect, useState } from 'react';
import './popular.css'
import { BsArrowRightShort } from 'react-icons/bs'
import { BsArrowLeftShort } from 'react-icons/bs'
import { MdExpandMore } from "react-icons/md";
import { MdExpandLess } from "react-icons/md";

import { BsDot } from 'react-icons/bs'

// Images ======================>
import trainer_diaz from '../../Assets/stuff/trainer_diaz.JPG'
import ayaulum from '../../Assets/stuff/IMG_9908.jpg'
import ernar from '../../Assets/stuff/IMG_0035_(copy).jpg'
import timur from '../../Assets/stuff/timur.jpg'
import irina from '../../Assets/stuff/irina.jpg'
import aibolat from '../../Assets/stuff/aibolat.jpg'
import raushan from '../../Assets/stuff/raushan.jpg'
import ilya from '../../Assets/stuff/ilya.jpg'
import marzhan from '../../Assets/stuff/marzhan.jpg'
import daulet from '../../Assets/stuff/daulet.jpg'
import alena from '../../Assets/stuff/alena.jpg'
import baurzhan from '../../Assets/stuff/baurzhan.jpg'
import veronika from '../../Assets/stuff/veronika.jpg'
import nazym from '../../Assets/stuff/nazym.jpg'
import dimat from '../../Assets/stuff/dimat.jpg'
import sagynysh from '../../Assets/stuff/sagynysh.jpg'
import esmina from '../../Assets/stuff/esmina.jpg'
import akzho from '../../Assets/stuff/akzho.jpg'
import uliya from '../../Assets/stuff/uliya.jpg'
import arman from '../../Assets/stuff/arman.jpg'
import aizada from '../../Assets/stuff/aizada.jpg'
import aisulu from '../../Assets/stuff/aisulu.jpg'
import natalya from '../../Assets/stuff/nataliya.jpg'
import dinmukhamed from '../../Assets/stuff/dinmukhamed.jpg'
import aituar from '../../Assets/stuff/aituar.jpg'
import zhanerke from '../../Assets/stuff/zhanerke.jpg'
import bakhytgul from '../../Assets/stuff/bakhytgul.jpg'
import amina from '../../Assets/stuff/amina.jpg'
import kamila from '../../Assets/stuff/kamilla.jpg'
import logoImage from '../../Assets/logo_uiren.jpeg'




// import AOS ======================>
import Aos from 'aos'
import 'aos/dist/aos.css'


const Data = [
  {
    id: 1,
    imgSrc: trainer_diaz,
    destTitle: 'Биксултанов Диаз Габитович',
    location: 'Шахматы',
    instagramUrl: 'https://www.instagram.com/p/Cv9wGSptjn5/?igsh=MW0yc21sYmc2bml1Yg=='
    
  },
  {
    id: 2,
    imgSrc: ayaulum,
    destTitle: 'Байгонырова Аяулым Амангелдиновна',
    location: 'Логопед',
    instagramUrl: 'https://www.instagram.com/p/CqaEMr2oYr0/?igsh=MTl5MmN2aHVlZnd4MQ=='

  },
  {
    id: 3,
    imgSrc: ernar,
    destTitle: 'Жарылқасын Ернар Маратұлы',
    location: 'Карате',
    instagramUrl: 'https://www.instagram.com/p/CqmTI7UoZH_/?igsh=MWZxOWFtN21wYnduYQ=='

  },
  {
    id: 4,
    imgSrc: timur,
    destTitle: 'Шаймарданов Тимур Камилевич',
    location: 'Шахматы',
    instagramUrl: 'https://www.instagram.com/p/Cq75i6qtnmR/?igsh=MWJ0NXAxMXdxcG8wbA=='

  },
  {
    id: 5,
    imgSrc: irina,
    destTitle: 'Парфёнова Ирина Юрьевна',
    location: 'Гимнастика',
    instagramUrl: 'https://www.instagram.com/p/CrLuesaNWGu/?igsh=MTBvazdsZnFocjRtYg=='

    
  },
  {
    id: 6,
    imgSrc: aibolat,
    destTitle: 'Жаншойнов Айболат Тлегенулы',
    location: 'Робототехника',
    instagramUrl: 'https://www.instagram.com/p/CrbOPk4IwGj/?igsh=MW41enJqZjNtcnB1Yg=='

  },
  {
    id: 7,
    imgSrc: raushan,
    destTitle: 'Садырбаева Раушан',
    location: 'Логопед',
    instagramUrl: 'https://www.instagram.com/p/Cri-vv5tOeD/?igsh=Z3N5MmhucHp0Mzhs'

  },
  {
    id: 8,
    imgSrc: ilya,
    destTitle: 'Хван Илья Владимирович',
    location: 'Тхэквондо',
    instagramUrl: 'https://www.instagram.com/p/CrvO2hXtVTD/?igsh=MXRhdGR4a2g0c3gxNQ=='

  },
  {
    id: 9,
    imgSrc: marzhan,
    destTitle: 'Азимханова Маржан',
    location: 'Зумба',
    instagramUrl: 'https://www.instagram.com/p/CsBhADoN5TI/?igsh=MmdhMnF6M294djZs'

  },
  {
    id: 10,
    imgSrc: daulet,
    destTitle: 'Жекишев Даулет Нурланович',
    location: 'Шахматы',
    instagramUrl: 'https://www.instagram.com/p/CsTy-ibttVz/?igsh=bW9sZDA0N2I5bDc2'

  },
  {
    id: 11,
    imgSrc: alena,
    destTitle: 'Хомякова Алёна',
    location: 'Скетчинг',
    instagramUrl: 'https://www.instagram.com/p/Csbq8a6NSdK/?igsh=eWVyd2p1b25zMzJy'

  },
  {
    id: 12,
    imgSrc: baurzhan,
    destTitle: 'Саитов Бауржан Тарейльевич',
    location: 'Тхэквондо',
    instagramUrl: 'https://www.instagram.com/p/Csk8VRUtw8t/?igsh=MWw0MHdnbTk2dGZwdQ=='

  },
  {
    id: 13,
    imgSrc: veronika,
    destTitle: 'Христенко Вероника Александровна',
    location: 'Скетчинг',
    instagramUrl: 'https://www.instagram.com/p/Cs--7_MNILu/?igsh=MWZ2dG9mNTE4bGdlZA=='

  },
  {
    id: 14,
    imgSrc: nazym,
    destTitle: 'Сарсебекова Назым',
    location: 'Английский язык',
    instagramUrl: 'https://www.instagram.com/p/CtJvVNXt43h/?igsh=MTVndHN5NXc2ZnJ0MQ=='

  },
  {
    id: 15,
    imgSrc: dimat,
    destTitle: 'Жұмабек Димат Ержанұлы',
    location: 'Тхэквондо',
    instagramUrl: 'https://www.instagram.com/p/CtLlpT2NY9X/?igsh=MThkbm9xaWljajQyaQ=='

  },
  {
    id: 16,
    imgSrc: sagynysh,
    destTitle: 'Акасова Сагыныш Сериккызы',
    location: 'Актерское мастерство',
    instagramUrl: 'https://www.instagram.com/p/Ct01Up8NdGk/?igsh=MTJzOWFrNGRhZWtqdA=='

  },
  {
    id: 17,
    imgSrc: esmina,
    destTitle: 'Амирова Эсмина Руслановна',
    location: 'Логопед-дефектолог',
    instagramUrl: 'https://www.instagram.com/p/CtkGecstiIM/?igsh=MTExeGQyaTdscmcxaQ=='

  },
  {
    id: 18,
    imgSrc: akzho,
    destTitle: 'Санат Акжо',
    location: 'Робототехника',
    instagramUrl: 'https://www.instagram.com/p/Ctkj-W7N7eN/?igsh=ZDJqNmMzbzl1M2sx'

  },
  {
    id: 19,
    imgSrc: uliya,
    destTitle: 'Пономарева Юлия',
    location: 'Скетчинг',
    instagramUrl: 'https://www.instagram.com/p/CtkpGE0oSkR/?igsh=bDlxOWtzdnp6dTAz'

  },
  {
    id: 20,
    imgSrc: arman,
    destTitle: 'Калабайулы Арман',
    location: 'Шахматы',
    instagramUrl: 'https://www.instagram.com/p/Ct04KRUt4x-/?igsh=Mmtmc2ZvbzlqbWZl'

  },
  {
    id: 21,
    imgSrc: aizada,
    destTitle: 'Касен Айзада Жанатовна',
    location: 'Художественная гимнастика',
    instagramUrl: 'https://www.instagram.com/p/Ct9kJonIqng/?igsh=MTQ0c3ppN3lhZGpndA=='

  },
  {
    id: 22,
    imgSrc: aisulu,
    destTitle: 'Молдажан Айсұлу Елюбекқызы',
    location: 'Английский язык',
    instagramUrl: 'https://www.instagram.com/p/Ct-z5dDo3P7/?igsh=MThtNjlqcXJib2Y3YQ=='

  },
  {
    id: 23,
    imgSrc: natalya,
    destTitle: 'Титова Наталья Андреевна',
    location: 'Современная хореография',
    instagramUrl: 'https://www.instagram.com/p/Ct_5rIHN5me/?igsh=OTZpN2hwMnJyeDd4'

  },
  {
    id: 24,
    imgSrc: dinmukhamed,
    destTitle: 'Кокежанов Динмухамед Капалбекулы',
    location: 'Шахматы',
    instagramUrl: 'https://www.instagram.com/p/CuGUz_DotYI/?igsh=MWY2c3U1NjgwbTEzbw=='

  },
  {
    id: 25,
    imgSrc: aituar,
    destTitle: 'Жукенов Айтуар Эрикович',
    location: 'Шахматы',
    instagramUrl: 'https://www.instagram.com/p/CuUAcElNfF0/?igsh=cmU2bzljMnpiNHpq'

  },
  {
    id: 26,
    imgSrc: zhanerke,
    destTitle: 'Какенова Жанерке Бериккалиқызы',
    location: 'Скетчинг',
    instagramUrl: 'https://www.instagram.com/p/CugCXqlIPbM/?igsh=dHZnd3hvbmhuMjc1'

  },
  {
    id: 27,
    imgSrc: bakhytgul,
    destTitle: 'Кундызбаева Бахыткуль Кенебаевна',
    location: 'Логопед',
    instagramUrl: 'https://www.instagram.com/p/CugR2R6tLs-/?igsh=dzN4M3BodWNsbTA='

  },
  {
    id: 28,
    imgSrc: amina,
    destTitle: 'Жакипова Амина Сериковна',
    location: 'Спортивная гимнастика',
    instagramUrl: 'https://www.instagram.com/p/CvG-BIUMRU3/?igsh=MWhoYXdudGZ6aDkzbA=='

  },
  {
    id: 29,
    imgSrc: kamila,
    destTitle: 'Дарибаева Камила Усеновна',
    location: 'Художественная гимнастика',
    instagramUrl: 'https://www.instagram.com/p/CvHXtAJsFmI/?igsh=MTZ0cjBybnExcmd0Mw=='

  },
];

const Popular = () => {
  const [visibleTrainers, setVisibleTrainers] = useState(Data.slice(0, 4));
  const [startIndex, setStartIndex] = useState(0);

  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const handleScrollRight = () => {
    if (startIndex + 4 < Data.length && !expanded) {
      setVisibleTrainers(Data.slice(startIndex + 1, startIndex + 5));
      setStartIndex(startIndex + 1);
    }
  };

  const handleScrollLeft = () => {
    if (startIndex > 0 && !expanded) {
      setVisibleTrainers(Data.slice(startIndex - 1, startIndex + 3));
      setStartIndex(startIndex - 1);
    }
  };

  const handleMoreClick = () => {
    if(!expanded) {
      setVisibleTrainers(Data);
      setExpanded(true);
    }
  }

  const handleLessClick = () => {
    if(expanded) {
      setVisibleTrainers(Data.slice(0, 4));
      setExpanded(false);
    }
  }

  const handleImageClick = (url) => {
    window.open(url, '_blank');
  };

  const handleButtonClick = (url) => {
    window.open(url, '_blank');
  };

  return (
    <section id="coaches" className='popular section container'>
      <div className="secContainter">

        <div className="secHeader flex">

          <div data-aos="fade-right" data-aos-duration="2500" className="textDiv">
            <h2 className='secTitle'>
              Наши преподаватели
            </h2>
            <p>
              Наши преподаватели в Uiren - профессионалы своего дела, готовые вдохновить, поддержать и помочь вам достичь ваших целей на занятиях!            </p>
          </div>

          <div className='iconsDiv flex'>
            <BsArrowLeftShort className='icon leftIcon' onClick={handleScrollLeft} />
            <BsArrowRightShort className='icon' onClick={handleScrollRight} />

            { expanded ? <MdExpandLess className='icon' onClick={handleLessClick}/> : <MdExpandMore className='icon' onClick={handleMoreClick}/> }
          </div>
        </div>

        <div className="mainContent grid">
          {/* Single Destination from the map Array */}
          {visibleTrainers.map(({ id, imgSrc, destTitle, location, instagramUrl }) => {
            return (
              <div key={id} data-aos="fade-up" className="singleDestination">
                <div className="destImage">
                  <img src={imgSrc} alt="" />
                  <div className="overlayInfo">
                    <h3>{destTitle}</h3>
                    <p>{location}</p>
                    <button onClick={() => handleButtonClick(instagramUrl)} className='btn'>
                      Перейти в Instagram
                    </button>
                  </div>
                </div>
                <div className="destFooter">
                  <div className="number">0{id}</div>
                  <div className="destText flex">
                    <h6>{location}</h6>
                    <span className='flex'>
                      <img src={logoImage} alt="Logo" style={{ width: '50px', height: 'auto' }} className='icon' />
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};



export default Popular;