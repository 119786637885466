import React, { useState, useEffect } from 'react';
import './review.css';
import review5 from '../../Assets/review/photo1718610077.jpeg'
import review6 from '../../Assets/review/photo1718610107.jpeg'
import review7 from '../../Assets/review/photo1718610125.jpeg'
import review8 from '../../Assets/review/photo1718610138.jpeg'
import review9 from '../../Assets/review/photo1718610163.jpeg'
import review10 from '../../Assets/review/photo1718610205.jpeg'
import review11 from '../../Assets/review/certificate_2.jpeg'
import review12 from '../../Assets/review/review6.jpg'
import review13 from '../../Assets/review/review7.jpg'
import review14 from '../../Assets/review/review8.jpg'
import review15 from '../../Assets/review/review9.jpg'
import review16 from '../../Assets/review/review10.jpg'
import review17 from '../../Assets/review/review11.jpg'
import review18 from '../../Assets/review/review12.jpg'
import review19 from '../../Assets/review/review13.jpg'
import review20 from '../../Assets/review/review14.jpeg'
import review21 from '../../Assets/review/review15.jpg'
import review22 from '../../Assets/review/review16.jpg'
import review23 from '../../Assets/review/review17.jpg'
import review24 from '../../Assets/review/review18.jpg'
import review25 from '../../Assets/review/review19.jpg'
import review26 from '../../Assets/review/review20.jpg'
import review27 from '../../Assets/review/review21.jpg'
import review28 from '../../Assets/review/review22.jpg'
import review29 from '../../Assets/review/review23.jpg'
import review30 from '../../Assets/review/review24.jpg'
import review31 from '../../Assets/review/review25.jpg'
import review32 from '../../Assets/review/review26.jpg'
import review33 from '../../Assets/review/review27.jpg'
import review34 from '../../Assets/review/review28.jpg'
import review35 from '../../Assets/review/review29.jpg'
import review36 from '../../Assets/review/review30.jpg'
import review37 from '../../Assets/review/review31.jpg'
import review38 from '../../Assets/review/review32.jpg'
import review39 from '../../Assets/review/review33.jpg'
import review40 from '../../Assets/review/review34.jpg'

import Aos from 'aos';
import 'aos/dist/aos.css';

const Reviews = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const reviews = [review5, review6, review7, review8, review9, review10, review11, review12, review13,
     review14, review15, review16, review17, review18, review19, review20, review21, review22, review23, review24, review25, review26, review27,
    review28, review29, review30, review31, review32, review33, review34, review35, review36, review37, review38, review39, review40];

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % reviews.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + reviews.length) % reviews.length);
  };

  return (
    <div className="reviews-container">
      <h1 data-aos="fade-up" data-aos-duration="2000" className="title">ОТЗЫВЫ ЦЕНТРОВ</h1>
      <div data-aos="fade-up" data-aos-duration="2500" className="reviews">
        {reviews.map((image, index) => (
          <div key={index} className="review" onClick={() => handleImageClick(index)}>
            <img src={image} alt={`Review ${index + 1}`} className="review-image" />
          </div>
        ))}
      </div>
      {modalOpen && (
        <div className="modal">
          <span className="close" onClick={closeModal}>&times;</span>
          <img src={reviews[currentImageIndex]} alt="Expanded Review" className="modal-image" />
          <span className="prev" onClick={prevImage}>&#10094;</span>
          <span className="next" onClick={nextImage}>&#10095;</span>
        </div>
      )}
    </div>
  );
};

export default Reviews;
