import React, { useEffect } from 'react';
import './footer.css';
import { ImFacebook } from 'react-icons/im';
import { FaWhatsapp } from 'react-icons/fa';  
import { AiFillInstagram } from 'react-icons/ai';
import logo from '../../Assets/logo_uiren.jpeg';  // Make sure the path is correct

import Aos from 'aos';
import 'aos/dist/aos.css';

const Footer = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const handleInstagramClick = () => {
    window.open('https://www.instagram.com/uiren__kz?igsh=MWd6dTN2dmJoMWl2cw==/', '_blank');
  };

  const handleSupportClick = () => {
    window.open('https://wa.me/+77073478844', '_blank');
  };

  return (
    <div id="contacts" className='footer'>
      <div className="secCotainer container grid">
        <div data-aos="fade-up" data-aos-duration="2000" className="logoDiv">
          <div className="footerLogo">
            <a href="#" className="logo flex"><img src={logo} alt="UIREN Logo" className="icon" /></a>
          </div>

          <div className="socials flex">
            <FaWhatsapp className="icon" onClick={handleSupportClick} />  
            <AiFillInstagram className="icon" onClick={handleInstagramClick} />
          </div>
        </div>

        <div data-aos="fade-up" data-aos-duration="2500" className="footerLinks">
          <span className="linkTitle">Информация</span>
          <li>
            <a href="#home">Главная</a>
          </li>
          <li>
            <a href="#offers">Услуги</a>
          </li>
          <li>
            <a href="#coaches">Преподаватели</a>
          </li>
        </div>

        <div data-aos="fade-up" data-aos-duration="3000" className="footerLinks">
          <span className="linkTitle">Полезные ссылки</span>
          <li>
            <a href="#" onClick={handleSupportClick}>Связаться с поддержкой</a>
          </li>
        </div>

        <div data-aos="fade-up" data-aos-duration="3000" className="footerLinks">
          <span className="linkTitle">Связаться с нами</span>
          <span className='phone'>+7 707 347 88 44</span>
          <span className="email">alibek@uiren.info</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
