import React, { useEffect } from 'react';
import './offer.css';
import { IoTimeOutline } from 'react-icons/io5';
import { BiCalendarCheck } from 'react-icons/bi';
import { BsArrowRightShort } from 'react-icons/bs';

import { MdOutlinePayments } from "react-icons/md";



// Images ======================>

import img_test from '../../Assets/img_test.PNG';
import img_mini from '../../Assets/img_mini.PNG';
import img_full from '../../Assets/img_full.PNG';
import img_smt from '../../Assets/chess_photo.PNG';


// Import Aos ======================>
import Aos from 'aos';
import 'aos/dist/aos.css';

const Data = [
  {
    id: 1,
    imgSrc: img_test,
    destTitle: 'Бесплатный пробный урок',
    // location: '1 уро',
    time: '30 минут',
    // days: 'Разовое посещение',
    // days: "Бесплатное посещение"
  },
  {
    id: 2,
    imgSrc: img_mini,
    destTitle: 'Формат сотрудничества 1',
    location: '8/12 занятий в месяц',
    time: '45 минут',
    days: '2/3 раза в неделю',
    // price: "Фиксированный оклад",
    payment: "Оплата с родителей"
  },
  {
    id: 3,
    imgSrc: img_smt,
    destTitle: 'Формат сотрудничества 2',
    location: '8/12 занятий в месяц',
    time: '45 минут',
    days: '2/3 раза в неделю',
    price: "Фиксированный оклад",
    // payment: "Оплата с родителей"
  }
];

const Offer = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <section id="offers" className='offer section container'>
      <div className="secContainter">
        <div data-aos="fade-up" data-aos-duration="2000" className="secIntro">
          <h2 className='secTitle'>
            Предложения
          </h2>
          <p>
            Получите незабываемый опыт обучения вашего ребенка в Uiren: качественные занятия, индивидуальный подход и команда опытных педагогов. Выбирайте формат, который будет удобнен вам!
          </p>
        </div>
        <div className="mainContent grid">
          {/* Single Offer from the map Array */}
          {Data.map(({ id, imgSrc, destTitle, location, time, days, price, payment, advice }) => {
            return (
              <div key={id} data-aos="fade-up" data-aos-duration="3000" className="singleOffer">
                <div className="destImage">
                  <img src={imgSrc} alt={destTitle} />
                </div>
                <div className="offerBody">
                  <h3>{destTitle}</h3> {/* Добавляем вывод destTitle */}
                  <div className="amenities flex">
                    <div className="singleAmenity flex">
                      <IoTimeOutline className="icon" />
                      <small>{time}</small>
                    </div>
                    <div className="singleAmenity flex">
                      <BiCalendarCheck className="icon" />
                      <small>{days}</small>
                    </div>
                    {price && (
                      <div className="singleAmenity flex">
                        <MdOutlinePayments className="icon" />
                        <small>{price}</small>
                      </div>
                    )}
                  </div>
                  {/* <div className="location flex">
                    <BsArrowRightShort className="icon" />
                    <small> Количество занятий: {location}</small>
                  </div> */}
                  {payment && (
                    <div className="location flex">
                      <BsArrowRightShort className="icon" />
                      <small>{payment}</small>
                    </div>
                  )}
                  {advice && (
                    <div className="location flex">
                      <BsArrowRightShort className="icon" />
                      <small>{advice}</small>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Offer;
