import React, { useState, useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import imgSwimming from '../../Assets/sections/swimming.webp';
import imgChess from '../../Assets/sections/chess.jpg';
import imgGymnastics from '../../Assets/sections/volga.jpg';
import imgSketching from '../../Assets/sections/sketching.jpg';
import imgRobots from '../../Assets/sections/robots.jpg';
import imgKarate from '../../Assets/sections/karate.jpg';
import imgLogoped from '../../Assets/sections/logoped.jpeg';
import imgJutsu from '../../Assets/sections/justu.jpg';
import imgJudo from '../../Assets/sections/judo.jpg';
import imgDance from '../../Assets/sections/contemporary_site.jpg';
import imgZumba from '../../Assets/sections/zumba.jpeg';
import imgVokal from '../../Assets/sections/vokal.jpg';
import imgTaekwondo from '../../Assets/sections/taekwondodo.jpg';
import imgActors from '../../Assets/sections/actors_kids.jpg';
import imgChoreography from '../../Assets/sections/choreography_kids.jpg';
import imgEnglish from '../../Assets/sections/english_lesson_kids.png';
import imgGymnasticss from '../../Assets/sections/gymnastics.jpg';
// import imgDrawing from '../../Assets/drawing.png';
// import imgDancing from '../../Assets/dancing.png';
// import imgSoccer from '../../Assets/soccer.png';
// import imgDevelopment from '../../Assets/development.png';
// import imgMath from '../../Assets/math.png';
// import imgSchoolPrep from '../../Assets/school_prep.png';
import './blog.css';  // Import the new CSS

const activities = [
  {
    id: 1, 
    name: 'Художественная гимнастика', 
    image: imgGymnastics,
    describtion: 'Художестенная гимнастика - это отличный способ улучшить физическое развитие ребенка, развить гибкость, координацию и дисциплину.'
  },
  { 
    id: 2, 
    name: 'Шахматы', 
    image: imgChess,
    describtion: 'Шахматы - это отличный способ развития логического мышления и улучшения памяти' 
  },
  { 
    id: 3, 
    name: 'Спортивная гимнастика', 
    image: imgGymnasticss,
    describtion: 'Спортивная гимнастика: Это вид спорта, включающий гибкость, силу, координацию и эстетические аспекты, такие как выступления с упражнениями на различных аппаратах.'
  },
  { 
    id: 4, 
    name: 'Тхэквондо', 
    image: imgTaekwondo,
    describtion: 'Тхэквондо: Восточное боевое искусство, фокусирующееся на технике ударов ногами, руками, блокировках и стратегии боя.'
  },
  { 
    id: 5, 
    name: 'Хореография', 
    image: imgChoreography,
    describtion: 'Хореография: Искусство создания и организации танцевальных движений и композиций.'
  },
  { 
    id: 6, 
    name: 'Скетчинг (ИЗО)', 
    image: imgSketching,
    describtion: 'Скетчинг (ИЗО): Это процесс создания быстрых и кратких рисунков или эскизов, часто используемых в изобразительном искусстве для отработки композиции и форм.'
  },
  { 
    id: 7, 
    name: 'Карате', 
    image: imgKarate,
    describtion: 'Карате: Японское боевое искусство, фокусирующееся на ударах руками и ногами, блокировках и самообороне.'
  },
  { 
    id: 8, 
    name: 'Зумба', 
    image: imgZumba,
    describtion: 'Зумба: Это фитнес-программа, сочетающая различные танцевальные стили под латиноамериканскую музыку, направленная на улучшение физической формы и веселье.'
  },
  { 
    id: 9, 
    name: 'Логопед', 
    image: imgLogoped,
    describtion: 'Логопед - это отличный способ помочь детям преодолевать речевые нарушения и развивать правильную и четкую речь.'
  },
  { 
    id: 10, 
    name: 'Актерское мастерство', 
    image: imgActors,
    describtion: 'Актерское мастерство: Искусство создания и интерпретации персонажей на сцене или перед камерой, включая использование голоса, мимики и эмоциональной выразительности.'
  },
  { 
    id: 11, 
    name: 'Английский язык', 
    image: imgEnglish,
    describtion: 'Английский язык: Один из самых распространенных языков в мире, изучение которого позволяет общаться на международном уровне и расширять лингвистические знания.'
  },
  { 
    id: 12, 
    name: 'Современные танцы', 
    image: imgDance,
    describtion: 'Современные танцы: Это разновидность танцевального искусства, которая интегрирует различные стили и направления, отражая современные тенденции и культурные влияния.'
  },
  { 
    id: 13, 
    name: 'Вокал', 
    image: imgVokal,
    describtion: 'Вокал: Искусство пения, включающее технику голоса, музыкальную интерпретацию и выражение эмоций через музыку.'
  },
  { 
    id: 14, 
    name: 'Робототехника', 
    image: imgRobots,
    describtion: 'Робототехника: Область, включающая проектирование, строительство и программирование роботов для выполнения различных задач.'
  },
  { 
    id: 15, 
    name: 'Плавание', 
    image: imgSwimming,
    describtion: 'Плавание: Спортивный вид, который развивает выносливость, силу и координацию, используя плавательные стили.'
  },
  { 
    id: 16, 
    name: 'Дзюдо', 
    image: imgJudo,
    describtion: 'Дзюдо: Японское боевое искусство, фокусирующееся на технике бросков, удержаний и захватов.'
  },
  { 
    id: 17, 
    name: 'Джиу-джитсу', 
    image: imgJutsu,
    describtion: 'Джиу-джитсу: Бразильское боевое искусство, основанное на технике борьбы на земле и самообороне.'
  },
];

const Blog = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const handleOpenModal = (post) => {
    setSelectedPost(post);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedPost(null);
  };

  return (
    <section className='blog container section'>
      <div className="secContainer">
        <div className="secIntro">
          <h2 data-aos="fade-up" data-aos-duration="2000" className='secTitle'>
            В каких направлениях могут развиваться Ваши дети?
          </h2>
          <p data-aos="fade-up" data-aos-duration="2500">
            Мы предлагаем Вам широкий выбор различных программ на любой вкус
          </p>
        </div>
        <div className="mainContainer" data-aos="fade-up" data-aos-duration="3000">
          {activities.map(activity => (
            <div key={activity.id} className="singlePost" onClick={() => handleOpenModal(activity)}>
              <div className="imgDiv">
                <img src={activity.image} alt={activity.name} />
              </div>
              <div className="postDetails">
                <h3>{activity.name}</h3>
              </div>
            </div>
          ))}
        </div>
      </div>
      {modalOpen && (
        <div className="modal" onClick={handleCloseModal}>
          <div className="modalContent" onClick={(e) => e.stopPropagation()}>
            <button className="close" onClick={handleCloseModal}>&times;</button>
            <h2>{selectedPost.name}</h2>
            <img src={selectedPost.image} alt={selectedPost.name} />
            <p>{selectedPost.describtion}</p>
          </div>
        </div>
      )}
    </section>
  );
};

export default Blog;
