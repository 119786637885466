import React, { useEffect, useState } from 'react'
import './about.css'
import teacher from '../../Assets/teacher.png'
import cooperation from '../../Assets/cooperation.png'
import knowledge from'../../Assets/knowledge.png'
import exam from '../../Assets/classroom.png'

// import video1 from '../../Assets/videos/video_1.mp4'
// import video2 from '../../Assets/videos/IMG_0813.MOV';
// import video3 from '../../Assets/videos/IMG_4328.MOV';
// import video4 from '../../Assets/videos/IMG_4359.MOV';
// import video5 from '../../Assets/videos/IMG_9890.MOV';


import { BsArrowRightShort } from 'react-icons/bs'
import { BsArrowLeftShort } from 'react-icons/bs'

// Import Aos ======================>
import Aos from 'aos'
import 'aos/dist/aos.css'


const About = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, [])


  const videos = [
    "https://www.youtube.com/embed/iKa8Hl1kvEE?autoplay=1&mute=1",
    "https://www.youtube.com/embed/fNxYVM_Y144?autoplay=1&mute=1",
    "https://www.youtube.com/embed/-w_fwGTCr9M?autoplay=1&mute=1",
    "https://www.youtube.com/embed/mSQ4MiKpsr4?autoplay=1&mute=1",
  ];

  const [index, setIndex] = useState(0);

  const [video, setVideo] = useState(videos[index]);

  const handleLeftClick = () => {
    const newIndex = (index - 1 + videos.length) % videos.length;
    setIndex(newIndex);
    setVideo(videos[newIndex]);
  };

  const handleRightClick = () => {
    const newIndex = (index + 1) % videos.length;
    setIndex(newIndex);
    setVideo(videos[newIndex]);
  };

  const handleVideoClick = () => {
    window.open('https://www.instagram.com/p/Cz3Vn8ECaAc/', '_blank');
  };

  return (
    <section className='about  section'>
      <div className="secContainer">

        <h2 className='title'>
          Наши преимущества
        </h2>


        <div className="mainContent container grid">

          <div data-aos="fade-up" data-aos-duration="2000" className="singleItem">
            <img src={exam} alt="Image" />
            <h3>Высококвалифицированные преподаватели</h3>
            <p>Наши преподаватели обладают глубокими знаниями и опытом, имеют спортивные разряды и сертификации. Эти достижения подтверждают их высокую квалификацию и профессионализм, что гарантирует качественное обучение и отличные результаты. </p>
          </div>

          <div data-aos="fade-up" data-aos-duration="2500" className="singleItem">
            <img src={knowledge} alt="Image" />
            <h3>Инновационный подход к обучению</h3>
            <p>Мы используем передовые методики и технологии для интересного и эффективного обучения. Отправляем детей на турниры, предоставляем гибкий график занятий и собственный инвентарь. Еженедельные видеоотчеты позволяют следить за прогрессом детей. </p>
          </div>

          <div data-aos="fade-up" data-aos-duration="3000" className="singleItem">
            <img src={cooperation} alt="Image" />
            <h3>Уникальные возможности сотрудничества</h3>
            <p>Мы предлагаем уникальные условия для наших партнеров, обеспечивая высокую долю прибыли и всестороннюю маркетинговую поддержку. Сотрудничая с нами, вы получаете % дохода от каждой программы и доступ к нашим маркетинговым ресурсам для увеличения вашей аудитории и роста вашего бизнеса.</p>
          </div>
        </div>

        <div className="videoCard container">
          <div className="cardContent grid">
            <div className="cardButtons">
              <div className='iconsDiv flex'>
                <BsArrowLeftShort className='icon leftIcon' onClick={handleLeftClick} />
                <BsArrowRightShort className='icon' onClick={handleRightClick} />
              </div>
            </div>
            <div className="cardText">
              <h2>Высококачественный процесс обучения!</h2>
              <p>Каждый процесс обучения в Uiren основан на систематическом подходе, включающем оценку потребностей, разработку высокоэффективных планов занятий нашими преподавателями и пошаговое воплощение этих планов. Наши опытные преподаватели поддерживают и направляют детей, обеспечивая регулярную обратную связь и непрерывный прогресс.</p>
            </div>
            <div className="cardVideo" onClick={handleVideoClick}>
            <iframe src={video}></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About