import React, { useEffect, useState } from 'react';
import './home.css';
import Aos from 'aos';
import 'aos/dist/aos.css';
import axios from 'axios';

import { FaWhatsapp } from 'react-icons/fa';
import { AiFillInstagram } from 'react-icons/ai';
import { MdOutlineEmail } from "react-icons/md";
import { FaPhone } from "react-icons/fa";

const Home = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleSendEmail = () => {
    axios.post('https://uiren-email.onrender.com/send-email', { email, phoneNumber })
      .then(response => {
        if (response.status === 200) {
          alert('Email sent successfully');
        } else {
          console.error('Error response:', response);
          alert('Error sending email');
        }
      })
      .catch(error => {
        console.error('Fetch error:', error);
        alert('Error sending email');
      });
  };

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const handleInstagramClick = () => {
    window.open('https://www.instagram.com/uiren__kz?igshid=MWd6dTN2dmJoMWl2cw==', '_blank');
  };

  const handleSupportClick = () => {
    window.open('https://wa.me/+77073478844', '_blank');
  };

  const handleEmailClick = () => {
    window.location.href = 'mailto:alibek@uiren.info';
  };

  const handlePhoneClick = () => {
    window.location.href = 'tel:+77073478844';
  };

  return (
    <section id="home" className="home">
      <div className="secContainer container">
        <div className="homeText">
          <h1 data-aos="fade-up" data-aos-duration="2000" className="title">
            Uiren - выездные спортивные и творческие услуги
          </h1>
          <p data-aos="fade-up" data-aos-duration="2500" className="subTitle">
            Расширяйте горизонты и поддерживайте активность своих детей вместе с нами!
          </p>
          <button data-aos="fade-up" data-aos-duration="3000" className="btn" onClick={handleOpenModal}>
            Оставьте свои контакты
          </button>
        </div>
      </div>

      {modalOpen && (
        <div className="modal">
          <div className="form">
            <div className="secContainer">
              <button className="closeButton" onClick={handleCloseModal}>
                &times;
              </button>
              <div className="contactSection">
                <div className="socials">
                  <MdOutlineEmail className="icon" onClick={handleEmailClick} />
                  <FaPhone className="icon" onClick={handlePhoneClick} />
                  <FaWhatsapp className="icon" onClick={handleSupportClick} />
                  <AiFillInstagram className="icon" onClick={handleInstagramClick} />
                </div>
              </div>
              <div className="pageTitle">
                <h1 className="title">Оставьте свои контакты и мы с вами свяжемся!</h1>
              </div>
              <div className="formContainer">
                <label htmlFor="email" className="formLabel">Ваше имя</label>
                <input
                  id="email"
                  type="email"
                  placeholder="Введите Ваше имя"
                  className="formInput"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <label htmlFor="phoneNumber" className="formLabel">Номер телефона</label>
                <input
                  id="phoneNumber"
                  type="text"
                  placeholder="+7 (777) 777 77-77"
                  className="formInput"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
                <button onClick={handleSendEmail} className="formButton">Отправить</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Home;
